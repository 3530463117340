.container {
  height: var(--sub-navigation-height);

  *[slot='front'] {
    grid-area: 1 / front;
  }

  *[slot='main'] {
    grid-area: 1 / main;
  }

  *[slot='back'] {
    grid-area: 1 / back;
  }
}

.innerContainer {
  @media (--v-from-lg) {
    grid-template-rows: 1fr;
    grid-template-columns: [front-start]1fr [front-end main-start]auto [main-end back-start]1fr [back-end];
    width: 100%;
  }
  container-type: normal;
}

.backgroundBlur {
  &::before {
    position: absolute;
    inset-block: 0;
    inset-inline: 0;
    content: '';
    backdrop-filter: blur(12px);
  }

  &[data-color-mode='light']::before {
    background: rgba(255 255 255 / 40%);
  }

  &[data-color-mode='dark']::before {
    background: rgba(0 0 0 / 40%);
  }
}

.ctas {
  justify-self: self-end;
}

.links {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 0 auto 0;
  @media (--v-until-md) {
    grid-template-columns: var(--v-space-pagemargin) auto var(
        --v-space-pagemargin
      );
  }
}

.link {
  font: var(--v-font-14);
  font-weight: var(--v-font-emphasis-weight);

  &:focus-visible {
    outline-offset: -2px;
  }

  &:not([aria-current]):hover {
    color: var(--v-color-foreground-primary);
    background: rgba(0 0 0 / 4%);
  }

  &[aria-current='page'] {
    color: var(--v-color-foreground-inverted);
    background: var(--v-color-foreground-primary);
  }
}

.scrollForward,
.scrollBack {
  position: relative;
  z-index: var(--v-index-navigation);
  transition: opacity 0.125s ease-in-out;
  & .scrollBackground {
    z-index: var(--v-index-navigation);
  }
}
.scrollBack {
  & .scrollBackground {
    background: linear-gradient(
      270deg,
      color-mix(in srgb, var(--v-color-background-primary) 0.0001%, transparent)
        0%,
      var(--v-color-background-primary) 22.5%
    );
  }
}

.scrollForward {
  & .scrollBackground {
    z-index: var(--v-index-navigation);
    background: linear-gradient(
      90deg,
      color-mix(in srgb, var(--v-color-background-primary) 0.0001%, transparent)
        0%,
      var(--v-color-background-primary) 22.5%
    );
  }
}

[dir='rtl'] {
  & .scrollBack .scrollBackground {
    background: linear-gradient(
      90deg,
      color-mix(in srgb, var(--v-color-background-primary) 0.0001%, transparent)
        0%,
      var(--v-color-background-primary) 22.5%
    );
  }
  & .scrollForward .scrollBackground {
    background: linear-gradient(
      270deg,
      color-mix(in srgb, var(--v-color-background-primary) 0.0001%, transparent)
        0%,
      var(--v-color-background-primary) 22.5%
    );
  }
}

[data-color-mode='light'] * {
  .cta {
    --button-accent-color: var(--v-color-foreground-accent-blue);
  }
  .lightLink[aria-current='page'] {
    color: var(--v-color-foreground-primary);
    background: var(--v-color-ornament-primary);
  }
}

[data-color-mode='dark'] * {
  .link:not([aria-current]):hover {
    background: #1f1f1f;
  }
}

.mobileCtaContainer {
  bottom: var(--v-space-24);

  @media (--v-from-md) {
    max-width: var(--v-size-grid-xs);
    inset-inline-end: var(--v-space-pagemargin);
  }
}

.mobileCtaInnerContainer {
  height: var(--v-space-48);
  transition: height 0.25s ease-in-out;

  &[aria-expanded='true'] {
    height: calc(
      (var(--v-space-48) * var(--amount-of-ctas)) +
        (var(--v-space-8) * calc(var(--amount-of-ctas) - 1))
    );
  }
}

.expandButton {
  max-width: 48px;
}
